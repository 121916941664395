import {ChartData, ChartOptions, TooltipItem} from "chart.js";
import {ChartStyling} from "../chart-styling";
import {TranslateService} from "@ngx-translate/core";
import {ChartDataModel} from "../chart-data-model";

export class LineChartConfig {

  private readonly now = new Date();
  private readonly currentYear = this.now.getFullYear();

  private readonly currentMonth = this.now.getMonth();

  private readonly currentDay = this.now.getDate();

  public chartData: ChartData<'line', (number | null)[]>;
  public chartOptions: ChartOptions<'line'>;
  constructor(private data: ChartDataModel,
              public yearToDisplay: number,
              public monthToDisplay: number | undefined,
              private translate: TranslateService) {

    const daysInMonth = new Array(new Date(yearToDisplay, (monthToDisplay || 0) + 1, 0).getDate()).fill(0).map((_, i) => i+1);

    this.chartData = {
      labels: daysInMonth,
      datasets: [
        {
          data: this.data.visibleContingentData,
          label: this.translate.instant('companyClient.consumption.contingent'),
          fill: false,
          borderColor: ChartStyling.contingentColor,
          pointBackgroundColor: ChartStyling.contingentColor,
          borderDash: [6, 4],
          backgroundColor: ChartStyling.contingentBackgroundColor,
          order: 0, // front
          yAxisID: 'y1'
        },
        {
          data: this.data.previousData,
          label: (yearToDisplay - 1).toString(),
          fill: true,
          borderColor: ChartStyling.previousColor,
          pointBackgroundColor: ChartStyling.previousColor,
          backgroundColor: ChartStyling.previousBackgroundColor,
          yAxisID: 'y',
          pointRadius: ctx => this.getPointRadius(-1, ctx.dataset.data as (number | null)[], ctx.dataIndex),
          segment: {
            borderDash: ctx => this.data.additionalInfoPrevious[ctx.p1DataIndex].isComplete ? undefined : [6, 4],
          },
          order: 2 // back
        },
        {
          data: this.data.currentData,
          label: yearToDisplay.toString(),
          fill: true,
          borderColor: ChartStyling.currentColor,
          backgroundColor: ChartStyling.currentBackgroundColor,
          yAxisID: 'y',
          pointBackgroundColor: ctx => this.getPointBgColors(ctx.dataIndex),
          pointStyle: 'circle',
          pointRadius: ctx => this.getPointRadius(ctx.dataIndex, ctx.dataset.data as (number | null)[], ctx.dataIndex),
          order: 1,
          segment: {
            borderDash: ctx => this.data.additionalInfoCurrent[ctx.p1DataIndex].isComplete ? undefined : [6, 4],
            borderColor: ctx => this.getPointBgColors(ctx.p1DataIndex),
            backgroundColor: ctx => this.getBgColors(ctx.p1DataIndex),
          }
        },
        {
          data: [],
          label: this.translate.instant('companyClient.consumption.contingentOverusedValue'),
          yAxisID: 'y'
        },
        {
          data: this.data.purchasedContingentData,
          label: this.translate.instant('TradingAction.PURCHASE'),
          fill: false,
          borderColor: ChartStyling.contingentPurchaseColor,
          pointBackgroundColor: ChartStyling.contingentPurchaseColor,
          borderDash: [6, 4],
          backgroundColor: ChartStyling.contingentPurchaseBackgroundColor,
          order: 0, // front
          yAxisID: 'y1'
        },
        {
          data: this.data.soldContingentData,
          label: this.translate.instant('TradingAction.SALE'),
          fill: false,
          borderColor: ChartStyling.contingentSaleColor,
          pointBackgroundColor: ChartStyling.contingentSaleColor,
          borderDash: [6, 4],
          backgroundColor: ChartStyling.contingentSaleBackgroundColor,
          order: 0, // front
          yAxisID: 'y1'
        }
      ]
    };

    const pluginOptions = {
      tooltip: {
        enabled: true,
        callbacks: {
          label(tooltipItem: TooltipItem<'bar'>): string | string[] {
            let value = tooltipItem.parsed.y;

            if(value == null) return '';

            const DATASET_VISIBLE_CONTINGENT_INDEX = 0;
            const DATASET_PREVIOUS_INDEX = 1;
            const DATASET_CURRENT_INDEX = 2;
            const DATASET_PURCHASED = tooltipItem.chart.data.datasets[4];
            const CURRENT_DATASET_INDEX = tooltipItem.datasetIndex;
            const CURRENT_DATA_INDEX = tooltipItem.dataIndex;

            let label = tooltipItem.dataset.label || '';

            // add purchases to contingent value
            if(CURRENT_DATASET_INDEX == DATASET_VISIBLE_CONTINGENT_INDEX) {
              let purchased = DATASET_PURCHASED.data[CURRENT_DATA_INDEX] as number | null;
              if(purchased != null) {
                value += purchased;
              }
            }

            // format number, add info suffix to label
            if(label) {
              const info =
                CURRENT_DATASET_INDEX == DATASET_PREVIOUS_INDEX ? data.additionalInfoPrevious :
                CURRENT_DATASET_INDEX == DATASET_CURRENT_INDEX ? data.additionalInfoCurrent : null;
              const suffix = info == null ? '' : ' (' + info[CURRENT_DATA_INDEX]?.dataSource +')';
              label += ': ' + value.toLocaleString('DE-ch') + ' kWh' + suffix;
            }
            return label;
          },
          footer(tooltipItems: TooltipItem<'line'>[]): string | string[] {
            let tooltipItem = tooltipItems[0];

            const DATASET_VISIBLE_CONTINGENT = tooltipItem.chart.data.datasets[0];
            const DATASET_CURRENT = tooltipItem.chart.data.datasets[2];
            const DATASET_LABEL = tooltipItem.chart.data.datasets[3];
            const DATASET_PURCHASED = tooltipItem.chart.data.datasets[4];
            const CURRENT_DATA_INDEX = tooltipItem.dataIndex;

            const contingentValue = (DATASET_VISIBLE_CONTINGENT.data[CURRENT_DATA_INDEX] as number) || 0;
            const purchaseValue = (DATASET_PURCHASED.data[CURRENT_DATA_INDEX] as number) || 0;
            const currentValue = (DATASET_CURRENT.data[CURRENT_DATA_INDEX] as number) || 0;

            return (currentValue > (contingentValue + purchaseValue) && contingentValue > 0) ?
              DATASET_LABEL.label?.replace('{{value}}',Math.floor((currentValue - (contingentValue + purchaseValue))).toLocaleString('DE-ch'))! :
              '';
          }
        },
        footerColor: ChartStyling.warningColor,
        footerFont: {
          weight: '900'
        }
      },
      legend: {
        display: false
      }
    };

    this.chartOptions = ChartStyling.getOptions(pluginOptions, this.data.getSuggestedMax(), this.translate.instant('companyClient.consumption.unit'), true);
  }

  getPointBgColors(dataIndex: number): string {
    return this.data.isAboveContingent(dataIndex) ? ChartStyling.warningColor : ChartStyling.currentColor;
  }

  getBgColors(dataIndex: number): string {
    return this.data.isAboveContingent(dataIndex) ? ChartStyling.warningBackgroundColor : ChartStyling.currentBackgroundColor;
  }

  getPointRadius(day: number, data: (null | number | undefined)[], index: number): number {
    if(this.isToday(day) || this.isIsolated(data, index)) return 4;
    return 0;
  }

  isIsolated(data: (null | number | undefined)[], index: number) {
    return (data[index-1] || null) === null && (data[index+1] || null) === null;
  }

  private isToday(dayIndex: number) {
    return dayIndex + 1 == this.currentDay && this.currentMonth == this.monthToDisplay && this.currentYear == this.yearToDisplay;
  }
}
