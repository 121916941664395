export type MpFilter = {mpid: string, vnb: string, bp: string, flagged: boolean};
export type AdditionalChartInfo = {isComplete: boolean, dataSource: string, key: string}
export class ChartDataModel {
  currentData: (null|number)[] = [];
  previousData: (null|number)[] = [];
  visibleContingentData: (null|number)[] = []; // visible meaning "height of blue bar". initial contingent minus sold contingent.
  soldContingentData: (null | number)[] = [];
  purchasedContingentData: (null | number)[] = [];
  additionalInfoCurrent: AdditionalChartInfo[] = [];
  additionalInfoPrevious: AdditionalChartInfo[] = [];
  additionalInfoContingent: AdditionalChartInfo[] = [];
  private suggestedMax: number | undefined = undefined;

  public getSuggestedMax(forceRecalc: boolean = false): number {
    if(this.suggestedMax == undefined || forceRecalc) {
      this.suggestedMax = this.calculateSuggestedMax();
    }
    return this.suggestedMax;
  }

  public isAboveContingent(i: number): boolean {
    return this.currentData[i] != null && this.visibleContingentData[i] != null &&
      (this.visibleContingentData[i]! > 0) && (this.currentData[i]! > (this.visibleContingentData[i]! + (this.purchasedContingentData[i] || 0)));
  }

  private calculateSuggestedMax(): number {
    let maxValue = Math.max(
      ...this.visibleContingentData.map((v: number | null, i: number) => v == null ? 0 : (v + (this.soldContingentData[i] || 0) + (this.purchasedContingentData[i] || 0))),
      ...this.previousData.map(v => v == null ? 0 : v),
      ...this.currentData.map(v => v == null ? 0 : v),
      0);
    return maxValue + maxValue/20;
  }
}

export class BarChartDataModel extends ChartDataModel {
  overusedData: (null | number)[] = [];

  constructor(chartDataModel: ChartDataModel) {
    super();
    this.visibleContingentData = chartDataModel.visibleContingentData.slice();
    this.previousData = chartDataModel.previousData.slice();
    this.additionalInfoPrevious = chartDataModel.additionalInfoPrevious.slice();
    this.additionalInfoCurrent = chartDataModel.additionalInfoCurrent.slice();
    this.additionalInfoContingent = chartDataModel.additionalInfoContingent.slice();
    this.soldContingentData = chartDataModel.soldContingentData.slice();
    this.purchasedContingentData = chartDataModel.purchasedContingentData.slice();

    this.overusedData = chartDataModel.visibleContingentData.map((contingent: null | number, i: number) =>
        contingent == null ? null :
        chartDataModel.currentData[i] == null ? null :
        chartDataModel.currentData[i]! < contingent + (chartDataModel.purchasedContingentData[i] || 0) ? null :
        chartDataModel.currentData[i]! - (contingent  + (chartDataModel.purchasedContingentData[i] || 0)));

    this.currentData = chartDataModel.currentData.map((current: number | null, i: number) =>
        current == null ? null :
        this.overusedData[i] == null ? current :
        current - this.overusedData[i]!);
  }
}
