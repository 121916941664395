import {Chart,ChartData,ChartMeta,ChartOptions,TooltipItem} from "chart.js";
import {ChartDataModel, BarChartDataModel} from "../chart-data-model";
import {TranslateService} from "@ngx-translate/core";
import {ChartStyling} from "../chart-styling";

export class BarChartConfig {
  public chartData: ChartData<'bar', (number | null)[]>;
  public chartOptions: ChartOptions<'bar'>;

  private data: BarChartDataModel;
  private dateHighlighter = {
    id: 'dateHighlighter',
    beforeDatasetsDraw(chart: Chart, _args: { index: number; meta: ChartMeta }, options: {selectedMonth: number | undefined, selectedYear: number}): boolean | void {
      const padding = 10;
      const y = chart.chartArea.top - padding / 2;
      const h = chart.height + padding;
      const wOneMonth = chart.scales['x'].getPixelForValue(1) - chart.scales['x'].getPixelForValue(0);
      const wOneMonthPadded = wOneMonth + padding;
      const paddingXOffset = (-wOneMonth - padding) / 2;

      if (options.selectedMonth !== undefined) {
        const xSelectedPadded = chart.scales['x'].getPixelForValue(options.selectedMonth) + paddingXOffset;

        chart.ctx.fillStyle = ChartStyling.selectedBackgroundColor;
        chart.ctx.fillRect(xSelectedPadded, y, wOneMonthPadded, h);
      }

      // future
      if(options.selectedYear == new Date().getFullYear()) {
        const currentMonth = new Date().getMonth();
        if(currentMonth < 11) {
          chart.ctx.fillStyle = ChartStyling.previousBackgroundColor;
          const xCurrent = chart.scales['x'].getPixelForValue(currentMonth+1);
          const wToEnd = chart.scales['x'].getPixelForValue(12) - xCurrent + padding;
          const xPadded = xCurrent + paddingXOffset;
          chart.ctx.fillRect(xPadded, y, wToEnd, h);
        }
        const xCurrent = chart.scales['x'].getPixelForValue(currentMonth);
        const xPadded = xCurrent + paddingXOffset;
        chart.ctx.save();
        chart.ctx.beginPath();
        chart.ctx.moveTo(xPadded, y);
        chart.ctx.lineTo(xPadded+wOneMonth, y);
        chart.ctx.lineTo(xPadded+wOneMonth, y+h);
        chart.ctx.lineTo(xPadded, y+h);
        chart.ctx.clip();
        for(let i = 0; i < (h / 10) + wOneMonth; i++) {
          chart.ctx.fill(new Path2D('M '+xPadded+' '+(y + (i * 10))+' l '+wOneMonth+' -'+wOneMonth+' v 5 l -'+wOneMonth+' '+wOneMonth+' Z'));
        }
        chart.ctx.restore();
      }

      // empty
      if(chart.data.datasets.map(d => d.data.length).reduce((p, c) => p + c) == 0) {
        chart.ctx.textAlign = 'center';
        chart.ctx.fillStyle = ChartStyling.previousColor;
        let textX = chart.scales['x'].getPixelForValue((chart.data.labels!.length-1)/2);
        let textY = chart.scales['y'].getPixelForValue(0.5);
        chart.ctx.fillText('No data', textX, textY);
      }
    }
  };

  constructor(cData: ChartDataModel,
              public yearToDisplay: number,
              public monthToHighlight: number | undefined,
              private translate: TranslateService) {

    this.data = new BarChartDataModel(cData);

    Chart.register(this.dateHighlighter);
    const datasets = [
      {
        data: this.data.visibleContingentData,
        label: this.translate.instant('companyClient.consumption.contingent'),
        backgroundColor: ChartStyling.contingentColor,
        borderColor: ChartStyling.contingentColor,
        categoryPercentage: 0.4,
        order: 1,
        stack: 'contingent'
      },
      {
        data: this.data.previousData,
        label: (yearToDisplay - 1).toString(),
        backgroundColor: ChartStyling.previousColor,
        borderColor: ChartStyling.previousColor,
        categoryPercentage: 0.4,
        order: 0
      },
      {
        data: this.data.currentData,
        label: yearToDisplay.toString(),
        backgroundColor: ChartStyling.currentColor,
        borderColor: ChartStyling.currentColor,
        categoryPercentage: 0.4,
        order: 2,
        stack: 'current'
      },
      {
        data: this.data.overusedData,
        label: this.translate.instant('companyClient.consumption.contingentOverused'),
        backgroundColor: ChartStyling.warningColor,
        borderColor: ChartStyling.warningColor,
        categoryPercentage: 0.4,
        order: 2,
        stack: 'current'
      },
      {
        data: [],
        label: this.translate.instant('companyClient.consumption.partlyContingent'),
        stack: 'current'
      }];

    if(this.data.purchasedContingentData.length > 0 && this.data.purchasedContingentData.some(c => c !== null)) {
      datasets.push({
        data: this.data.purchasedContingentData,
        label: this.translate.instant('TradingAction.PURCHASE'),
        backgroundColor: ChartStyling.contingentPurchaseColor,
        borderColor: ChartStyling.contingentPurchaseColor,
        categoryPercentage: 0.4,
        order: 1,
        stack: 'contingent'
      });
    }

    if(this.data.soldContingentData.length > 0 && this.data.soldContingentData.some(c => c !== null)) {
      datasets.push({
        data: this.data.soldContingentData,
        label: this.translate.instant('TradingAction.SALE'),
        backgroundColor: ChartStyling.contingentSaleColor,
        borderColor: ChartStyling.contingentSaleColor,
        categoryPercentage: 0.4,
        order: 1,
        stack: 'contingent'
      });
    }


    this.chartData = {
      labels: this.translate.instant('months'),
      datasets: datasets
    };

    let pluginOptions = {
      dateHighlighter: {
        enabled: true,
        selectedMonth: this.monthToHighlight,
        selectedYear: this.yearToDisplay
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label(tooltipItem: TooltipItem<'bar'>): string | string[] {
            let value = tooltipItem.parsed.y;

            if(value == null) return '';

            const DATASET_VISIBLE_CONTINGENT_INDEX = 0;
            const DATASET_PREVIOUS_INDEX = 1;
            const DATASET_CURRENT_INDEX = 2;
            const DATASET_OVERUSED = tooltipItem.chart.data.datasets[3];
            const DATASET_LABEL = tooltipItem.chart.data.datasets[4];
            const DATASET_PURCHASED = tooltipItem.chart.data.datasets[5];
            const CURRENT_DATASET_INDEX = tooltipItem.datasetIndex;
            const CURRENT_DATA_INDEX = tooltipItem.dataIndex;

            let label = tooltipItem.dataset.label || '';

            let overused = DATASET_OVERUSED.data[CURRENT_DATA_INDEX] as number;

            // add purchases to contingent value
            if(CURRENT_DATASET_INDEX == DATASET_VISIBLE_CONTINGENT_INDEX &&
              DATASET_PURCHASED?.backgroundColor == ChartStyling.contingentPurchaseColor) {

              let purchased = DATASET_PURCHASED.data[CURRENT_DATA_INDEX] as number | null;
              if(purchased != null) {
                value += purchased;
              }
            }

            // add overused to curent
            if(CURRENT_DATASET_INDEX == DATASET_CURRENT_INDEX && overused != null) {
              value += overused;
            }

            // format number, add info suffix to label
            if(label) {
              const info =
                CURRENT_DATASET_INDEX == DATASET_PREVIOUS_INDEX ? cData.additionalInfoPrevious :
                CURRENT_DATASET_INDEX == DATASET_CURRENT_INDEX ? cData.additionalInfoCurrent :
                CURRENT_DATASET_INDEX == DATASET_VISIBLE_CONTINGENT_INDEX ? cData.additionalInfoContingent : null;
              const suffix =
                info == null ? '' :
                CURRENT_DATASET_INDEX == DATASET_VISIBLE_CONTINGENT_INDEX && !info[tooltipItem.dataIndex]?.isComplete ?
                  ' ' + DATASET_LABEL.label :
                  ' (' + info[tooltipItem.dataIndex]?.dataSource +')';

              label += ': ' + value.toLocaleString('DE-ch') + ' kWh' + suffix;
            }
            return label;
          }
        },
        footerColor: ChartStyling.warningColor,
        footerFont: {
          weight: ChartStyling.footerWeight
        }
      },
      legend: {
        onClick: null,
        labels: {
          boxWidth: 21,
          padding: 15,
          filter: (item: any) => item.text !== this.translate.instant('companyClient.consumption.partlyContingent')
        }
      }
    };

    this.chartOptions = ChartStyling.getOptions(pluginOptions, this.data.getSuggestedMax(), this.translate.instant('companyClient.consumption.unit'));
  }
}
