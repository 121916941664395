import {Component, OnInit} from '@angular/core';
import {CompanypartnersBaseComponent} from "../companypartners-base.component";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {COMPANYPARTNERS_API_ENDPOINTS_LIST} from "../api-endpoints-list";

@Component({
  selector: 'contingent-trade-info',
  templateUrl: './contingent-trade-info.component.html',
  styleUrls: ['./contingent-trade-info.component.scss']
})
export class ContingentTradeInfoComponent extends CompanypartnersBaseComponent implements OnInit {
  prefix = 'companyClient.trade.';
  ctPrefix = 'companyClient.objectProperties.trade.';
  businessPartners: {id: string, vnb: number, name: string}[] = [];
  edit = false;

  tradingTimestampTime = new FormControl('', [Validators.pattern("^[0-9][0-9]?:[0-9][0-9]?$")]);
  tradingTimestampDate = new FormControl();

  contingentTradeForm: FormGroup = this.fb.group({
    tradeId: [0],
    cpPartnerId: [0],
    vnbPartnerId: [0],
    businessPartnerId: [''],
    tradingAction: ['', [Validators.required]],
    year: [new Date().getFullYear(), [Validators.required]],
    month: ['', [Validators.required]],
    tradingQuantity: [null, [Validators.required]],
    tradingTimestamp: [''],
    tradingPlatformOrIntermediary: [''],
    tradingContingentId: [''],
    tpCompanyName: ['', [Validators.required]],
    tpUidNumber: [''],
    tpResponsiblePerson: [''],
    tpContingentId: [''],
    tpContactFirstName: [''],
    tpContactName: [''],
    tpAddressStreet: [''],
    tpAddressHouseNumber: [''],
    tpAddressSuffix: [''],
    tpAddressZipCode: [''],
    tpAddressTown: [''],
    tpEmail: [''],
    cpCompanyName: ['', [Validators.required]],
    cpUidNumber: [''],
    cpResponsiblePerson: [''],
    cpContingentId: [''],
    cpContactFirstName: [''],
    cpContactName: [''],
    cpAddressStreet: [''],
    cpAddressHouseNumber: [''],
    cpAddressSuffix: [''],
    cpAddressZipCode: [''],
    cpAddressTown: [''],
    cpEmail: ['']
  });

  ngOnInit() {
    this.meteringDataSubscription$.subscribe(consumptionData => {
      if(consumptionData !== undefined) {
        this.businessPartners = consumptionData.businessPartners.map(bp => ({name: bp.name, vnb: bp.meteringPoints[0].mpa.id, id: bp.meteringPoints[0].businessPartnerId}));
        this.initialized = true;
      } else {
        this.meteringDataService.getConsumptionData(this.partnerId);
      }
    });

    const tradeId = this.activatedRoute.snapshot.paramMap.get('id');
    if(tradeId !== null) {
      this.edit = true;
      this.requestService.doApiRequest(COMPANYPARTNERS_API_ENDPOINTS_LIST.getContingentTrade, {tradeId: tradeId, cpPartnerId: this.partnerId, vnbPartnerId: 0, businessPartnerId: ''})
        .then(result => {
          if(result.status == 'success' && result.data.result.status !== 'ERROR') {
            const trade = result.data.contingentTrade;
            let date: Date | null = new Date(trade.tradingTimestamp);
            if(date.getTime() === 0) {
              date = null;
            }
            this.contingentTradeForm.patchValue({
              ...trade,
              tradingTimestamp: date
            });
            this.tradingTimestampTime.patchValue(date !== null ? (date.getHours()+':'+date.getMinutes()) : null);
            this.tradingTimestampDate.patchValue(date);
          } else {
            this.router.navigate(['/company/contingents/trade']);
            this.handleError(result.data);
          }
        });
    } else {
      this.requestService.doApiRequest(COMPANYPARTNERS_API_ENDPOINTS_LIST.getCompanyPartner, {partnerId: this.partnerId})
        .then((result: {status: string, data: any}) => {
          if(result.status == 'success') {

            const cpInfo = result.data.companyPartner;
            const sessionInfo = this.sessionService.getCurrentCompanyPartnerInfo()?.companyPartnerSettings;
            this.contingentTradeForm.patchValue({
              cpPartnerId: this.partnerId,
              cpContactFirstName: cpInfo.contactFirstName,
              cpContactName: cpInfo.contactName,
              cpAddressStreet: cpInfo.addressStreet,
              cpAddressHouseNumber: cpInfo.addressHouseNumber,
              cpAddressZipCode: cpInfo.addressZipCode,
              cpAddressTown: cpInfo.addressTown,
              cpEmail: sessionInfo?.contactEmail
            });
          }
        });
    }
  }
  patchIds(businessPartner: {id: string, vnb: number, name: string}) {
    this.contingentTradeForm.patchValue({vnbPartnerId: businessPartner.vnb, businessPartnerId: businessPartner.id});
  }

  onSubmit() {
    this.isSendingRequest = true;
    const date: Date = this.tradingTimestampDate.value;
    if(date !== null) {
      const time = (this.tradingTimestampTime.value || '0:0').split(':');
      this.contingentTradeForm.patchValue({
        tradingTimestamp: new Date(date.getFullYear(), date.getMonth(), date.getDate(), parseInt(time[0]), parseInt(time[1]))
      });
    } else {
      this.contingentTradeForm.patchValue({
        tradingTimestamp: new Date(0)
      });
    }

    const apiConfigDefinition = COMPANYPARTNERS_API_ENDPOINTS_LIST.createNewContingentTrade;
    this.requestService.doApiRequest(apiConfigDefinition, this.contingentTradeForm.getRawValue())
      .then((result: {status: string, data: any}) => {
        this.isSendingRequest = false;
        if(result.status == 'success' && result.data.result.status != 'ERROR') {
          this.meteringDataService.getConsumptionData(this.partnerId);
          this.router.navigate(['/company/contingents/trade']);
        } else {
          this.handleError(result.data);
        }
      });
  }

  onDelete() {
    const apiConfigDefinition = COMPANYPARTNERS_API_ENDPOINTS_LIST.deleteContingentTrade;
    this.requestService.doApiRequest(apiConfigDefinition, {tradeId: this.contingentTradeForm.controls['tradeId'].value, cpPartnerId: this.partnerId})
      .then((result: {status: string, data: any}) => {
        this.isSendingRequest = false;
        if(result.status == 'success') {
          this.meteringDataService.getConsumptionData(this.partnerId);
          this.router.navigate(['/company/contingents/trade']);
          this.openSnackbar(this.translate.instant('companyClient.trade.deleted'), 20000);
        } else {
          this.handleError(result.data);
        }
      });
  }

  async handleError(result: any) {
    const msg = await this.requestService.convertApiErrorToString(result);
    this.openSnackbar(msg, 20000);
  }
}
