import {Component, Input, OnChanges} from '@angular/core';
import {ConsumptionDataCompanyPartner} from "../consumption-data-model";
import {ChartDataMapper} from "../chart-data-mapper";
import {ChartData, ChartOptions} from "chart.js";
import {LineChartConfig} from "./line-chart-config";
import {TranslateService} from "@ngx-translate/core";
import {MpFilter} from "../chart-data-model";

@Component({
  selector: 'line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss']
})
export class LineChartComponent  implements OnChanges {

  @Input() yearToDisplay: number = 0;
  @Input() mpFilter: MpFilter[] | undefined;
  @Input() monthToDisplay: number | undefined;
  @Input() consumptionData: ConsumptionDataCompanyPartner | undefined



  ngOnChanges(): void {
    if(this.consumptionData != undefined) {
      const dataModel = ChartDataMapper.toChartDatasetData(this.translate, this.consumptionData, this.yearToDisplay, this.monthToDisplay, this.mpFilter, true);
      const chartModel = new LineChartConfig(dataModel, this.yearToDisplay, this.monthToDisplay, this.translate);
      this.chartData = chartModel.chartData;
      this.chartOptions = chartModel.chartOptions;
    }
  }

  chartData?: ChartData<'line', (number | null)[]>;
  chartOptions?: ChartOptions<'line'>;

  constructor(private translate: TranslateService) {
  }

}
